<template>
  <v-container v-if="vueAuditoria">
    <template>
      <v-row>
        <v-col cols="12">
          <PageToolbar title="Audits" icon="security" dark />
        </v-col>
        <v-col cols="12">
          <ListaAudits />
        </v-col>
      </v-row>
    </template>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Usuarios",
  components: {
    ListaAudits: () => import("./components/ListaAudits.vue"),
  },
  data: () => ({}),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueAuditoria() {
      return this.getAccess("vueAuditoria", "menu");
    },
  },
  mounted() {},
};
</script>

<style></style>
